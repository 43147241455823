import React, { useContext } from "react";
import moment from "moment";
import { LanternsBackgroundContext } from "@components/Layout";
import Lantern from "./Lantern.component";
import { useWindowSize } from "@hooks";
import { LanternsBackgroundContextInteraface } from "@types";
import { LanternsType } from "@components/LanternsScreen/LanternsScreen.container";

export type LanternType = {
  latestDate: number;
  earliestDate: number;
  lantern: LanternsType;
  setOpenLantern: (lantern: LanternsType) => void;
};

const LanternContainer = (props: LanternType) => {
  const lanternsBgContext: LanternsBackgroundContextInteraface = useContext(
    LanternsBackgroundContext
  );

  const [windowWidth, windowHeight] = useWindowSize();
  const sentDateConverted = Number(moment(props.lantern.sentDate).format("X"));

  const percentageOfCurrentDate =
    0.05 + // this is 5% added to avoid having 0
    1 - // instead of getting 0.05 for the highest, this will just invert so we will get 0.95 instead
    1 /
      ((props.earliestDate - props.latestDate) /
        (sentDateConverted - props.latestDate));

  return (
    <Lantern
      setOpenLantern={props.setOpenLantern}
      x={Math.random() * windowWidth - 180}
      y={Math.random() * windowHeight - 20}
      z={percentageOfCurrentDate}
      shouldDisplayTooltip={lanternsBgContext.shouldLanternsDisplayWithTooltip}
      lantern={props.lantern}
    />
  );
};

export default LanternContainer;
