import React, { useEffect, useContext, Dispatch } from "react";
import useSound from "use-sound";
import {
  GlobalStateContext,
  GlobalDispatchContext,
  GlobalStateInterface,
  BG_TRACK_ACTIONS,
} from "@context/GlobalContextProvider";
import backgroundTune from "@assets/audio/183_enliven_meditation_trim.mp3";
export default function BackgroundMusic() {
  const [play, { pause, isPlaying, duration }] = useSound(backgroundTune, {
    volume: 0.2,
    // @ts-ignore - any unrecognized props will be passed to Howler, so in this case 'loop' will work
    loop: true,
  });

  const state: GlobalStateInterface = useContext(GlobalStateContext);
  const dispatch: Dispatch<any> = useContext(GlobalDispatchContext);

  const UnmuteIcon = () => (
    <img
      src={require("@assets/icons/unmute.svg")}
      alt="Unmute background track icon"
      className="bgTrackControl"
    />
  );
  const MuteIcon = () => (
    <img
      src={require("@assets/icons/mute.svg")}
      alt="Mute background track icon"
      className="bgTrackControl"
    />
  );

  React.useEffect(() => {
    if (state.backgroundTrack) {
      document.addEventListener("visibilitychange", (event) => {
        if (document.visibilityState == "visible") {
          // state.backgroundTrack.play();
        } else {
          state.backgroundTrack.pause();
        }
      });
    }
  }, [state.backgroundTrack]);

  useEffect(() => {
    if (!state.backgroundTrack && duration) {
      dispatch({
        type: BG_TRACK_ACTIONS.LOADED,
        payload: { play, pause, isPlaying },
      });

      // Autoplay only for non-Safari browsers
      // Otherwise user should manually trigger audio play
      if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        play();

        dispatch({
          type: BG_TRACK_ACTIONS.TOGGLE,
        });
      }
    }
  }, [duration]);

  return (
    <div
      className="bgTrackControlWrap"
      onClick={() => {
        if (state.isBGTrackCurrentlyPlaying) {
          state.backgroundTrack.pause();
        } else {
          state.backgroundTrack.play();
        }
        dispatch({ type: BG_TRACK_ACTIONS.TOGGLE });
      }}
    >
      {state.isBGTrackCurrentlyPlaying ? <UnmuteIcon /> : <MuteIcon />}
    </div>
  );
}
