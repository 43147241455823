export const FONTS = [
  "'Covered By Your Grace', cursive",
  "'Homemade Apple', cursive",
  "'Mansalva', cursive",
  "'Mr Dafoe', cursive",
  "'Permanent Marker', cursive",
  "'Noto Serif TC', serif",
];

export const DEFAULT_MESSAGE = "Write your wishes...";
