import React from "react";
import { useWindowSize } from "@hooks";
import { LanternsType } from "@components/LanternsScreen/LanternsScreen.container";

const LanternTooltp = ({ lantern, customStyle }) => {
  return (
    <div className="tooltip bold" style={customStyle}>
      {lantern.user}, {lantern.location}
      <img
        src={require("@assets/icons/plus.svg")}
        alt="Tooltip name and location pointer icon"
      />
    </div>
  );
};

export type LanternType = {
  x: number;
  y: number;
  z: number;
  shouldDisplayTooltip: boolean;
  lantern: LanternsType;
  setOpenLantern: (lantern: LanternsType) => void;
};

function Lantern(props: LanternType) {
  const [windowWidth, windowHeight] = useWindowSize();

  const typeOfLantern =
    props.lantern.lanternStyle !== 2
      ? props.lantern.lanternStyle
      : Math.random() < 0.5
      ? 2
      : 3;
  const id = (Math.random() * 100000).toFixed();
  const img_url = require(`../../assets/lanternImages/lantern_${typeOfLantern}.png`);
  const plusOrMinus = Math.random() < 0.5 ? -1 : 1;
  const animationDuration = Math.random() * 80 + (1 - props.z) * 50 + 20;
  const rotateDegree = (Math.random() * 10 + 10) * plusOrMinus;
  const moveAside = Math.random() * 5 * plusOrMinus;
  const startAtY = windowHeight + props.y;
  const finishAtY =
    (-(windowHeight + props.y) * (1 / props.z) - windowHeight * props.z) * 1.3;

  const r25 = (Math.random() * 4 + 23).toFixed();
  const r75 = (Math.random() * 6 + 72).toFixed();

  const zIndex = props.shouldDisplayTooltip
    ? 100
    : Number((props.z * 10).toFixed());

  const animationOptions = `${animationDuration}s linear infinite -${
    animationDuration * Math.random()
  }s`;

  const propsZ = props.z;

  return (
    <>
      <style>{`
        @keyframes fly${id} {
            0% { transform:     scale(${props.z.toFixed(2)}) 
                              translateX(0px)
                              translateY(0px) 
                              translateZ(0); 
                            }
            ${r25}% { transform: scale(${props.z}) 
                              translateX(${-1 * moveAside}px) 
                              translateY(${finishAtY * 0.25}px);
                            }
            ${r75}% { transform: scale(${props.z}) 
                              translateX(-${moveAside}px) 
                              translateY(${finishAtY * 0.75}px);
                            }
            100% { transform: scale(${props.z}) 
                              translateX(0px) 
                              translateY(${finishAtY}px)
                              translateZ(0); 
                            }
        }

        @keyframes wiggle${id} {
            0% { transform: rotate(0deg) }
            ${r25}% { transform: rotate(${-1 * rotateDegree}deg) }
            ${r75}% { transform: rotate(${rotateDegree}deg) }
            100% { transform: rotate(0deg) }                             
        }
    `}</style>
      <div>
        <figure
          className="lantern"
          style={{
            zIndex,
            animation: `fly${id} ${animationOptions}`,
            top: startAtY,
            left: props.x,
          }}
        >
          <img
            onClick={() => props.setOpenLantern(props.lantern)}
            src={img_url}
            alt="lantern"
            style={{
              animation: `wiggle${id} ${animationOptions}`,
              filter: `brightness(${props.z * 50 + 45}%) blur(${
                (1 - props.z) * 10
              }px)`,
            }}
          />
          {props.shouldDisplayTooltip && (
            <LanternTooltp
              lantern={props.lantern}
              customStyle={{
                transform: `scale(${1 / props.z})`,
              }}
            />
          )}
        </figure>
      </div>
    </>
  );
}

export default Lantern;
