import React, { FunctionComponent, createContext, forwardRef } from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import { NavSide, NavLink } from "@components/NavSide";
import BackDrop from "@components/BackDrop";
import BackgroundMusic from "@components/BackgroundMusic";
import LanternsScreenContainer from "@components/LanternsScreen/LanternsScreen.container";
import { useWindowSize, useClasses } from "@hooks";
import { LanternsBackgroundContextInteraface } from "@types";
import ParallaxLanterns from "@components/ParallaxLanterns";
import publicisLogoImg from "@assets/images/logo.svg";
import "@styles/App.scss";

const pathToNavLink = {
  "/about": NavLink.About,
  "/create": NavLink.Create,
};

interface LayoutProps {
  className?: string;
  currentPath?: string;
  videoBackground?: string;
  withBackdrop?: boolean;
  withGoBackCross?: boolean;
  lanternsBackground?: boolean;
  exploreLanterns?: boolean;
  pageTitle?: string;
}

const GoBackCross = () => (
  <section className="crossWrapper">
    <img
      src={require("@assets/icons/cross.svg")}
      alt="Go Back Cross"
      onClick={() => navigate(-1)}
      className="goBackCross"
    />
  </section>
);

const VideoBackground = (props) => (
  <video autoPlay muted loop preload="auto" className={props.className}>
    <source src={props.src} type="video/mp4" />
  </video>
);

const createLanternsBGContext: (
  lanternsBGCtx: LanternsBackgroundContextInteraface
) => object = (shouldLanternsDisplayWithTooltip: boolean): object => ({
  shouldLanternsDisplayWithTooltip,
});

export const LanternsBackgroundContext = createContext(
  createLanternsBGContext(false)
);

export const Layout: FunctionComponent<LayoutProps> = React.forwardRef(
  (
    {
      children,
      className = "",
      currentPath,
      withBackdrop = true,
      videoBackground = null,
      lanternsBackground = null,
      withGoBackCross = false,
      exploreLanterns = false,
      pageTitle = "The Lantern Festival | EMBRACE PS",
      ...otherProps
    },
    ref
  ) => (
    <LanternsBackgroundContext.Provider
      value={createLanternsBGContext(/\/(lanterns)\/?$/.test(currentPath))}
    >
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />
        <title>{pageTitle}</title>
      </Helmet>
      <section className="logoWrap" onClick={() => navigate("/")}>
        <img src={publicisLogoImg} alt="Publicis Groupe logo" />
      </section>
      <div
        ref={ref}
        className={useClasses(["fullScreenTransparent", className])}
        {...otherProps}
      >
        {children}
      </div>
      <BackgroundMusic />
      <NavSide
        shouldDisplay={!/about|create/.test(currentPath)}
        currentlyActive={pathToNavLink[currentPath]}
      />
      {videoBackground && (
        <VideoBackground src={videoBackground} className="videoBackground" />
      )}
      {lanternsBackground && <ParallaxLanterns />}
      {withGoBackCross && <GoBackCross />}
      {withBackdrop && <BackDrop opacity={0.55} />}
      {exploreLanterns && <LanternsScreenContainer />}
    </LanternsBackgroundContext.Provider>
  )
);
