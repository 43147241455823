import React, { useRef, useEffect } from "react";
import moment from "moment";
import { LanternsType } from "@components/LanternsScreen/LanternsScreen.container";
import BackDrop from "@components/BackDrop";
import { FONTS } from "../../constants/fonts";

type ShowLanternProps = {
  lantern: LanternsType;
  showSender?: boolean;
  textToCopy?: string;
  clickOnLantern: () => void;
  clickOffLantern: () => void;
};

function ShowLantern(props: ShowLanternProps) {
  const lanternPlaceholder = useRef(null);

  const { lantern } = props;

  const handleClick = (e) => {
    if (lanternPlaceholder.current.contains(e.target)) {
      if (props.clickOnLantern) {
        props.clickOnLantern();
      }
      return;
    }
    if (props.clickOffLantern) {
      props.clickOffLantern();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <>
      <BackDrop opacity={0.4} zIndex={100} />
      <div className="showLantern" ref={lanternPlaceholder}>
        <img
          className="lanternStyle"
          src={require(`@assets/lanternImages/lantern_${lantern.lanternStyle}.png`)}
        />
        <div
          className={`message fontType${FONTS.indexOf(
            lantern.message.fontType
          )}`}
          style={{ fontFamily: lantern.message.fontType }}
        >
          {lantern.message.message}
        </div>
        {lantern.sticker && (
          <img
            className="sticker"
            src={require(`@assets/images/stickers/${lantern.sticker}`)}
            alt={lantern.sticker}
          />
        )}

        {props.showSender && (
          <div className="sender">
            Released{" "}
            {moment(
              moment(lantern.sentDate).format("YYYYMMDDkkmmss"),
              "YYYYMMDDkkmmss"
            ).fromNow()}{" "}
            by
            <span className="user">
              {lantern.user}, {lantern.location}
            </span>
          </div>
        )}
      </div>
      <section className="crossWrapper">
        <img
          src={require("@assets/icons/cross.svg")}
          alt="Go Back Cross"
          onClick={() => props.clickOffLantern()}
          className="goBackCross"
        />
      </section>
    </>
  );
}

export default ShowLantern;
