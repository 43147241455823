import React, { useState, useEffect, useRef } from "react";
import { removeExtension } from "../../utils";
import { STICKERS } from "../../constants/stickers";
import { useWindowSize } from "@hooks";

type ParallaxLanternsTypes = {};

const ParallaxLanterns = (props: ParallaxLanternsTypes) => {
  const [windowWidth, windowHeight] = useWindowSize();
  return (
    <>
      <div className="parallaxContainer" style={{ background: "black" }}>
        <style>{`
                  @keyframes fly {
                      0% { transform:  translateY(0px)  }
                      100% { transform: translateY(-${windowHeight}px)  }
                  }
      `}</style>
        <div
          className="layer"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            animation: "fly 30s linear infinite",
          }}
        >
          <img
            style={{ height: windowHeight }}
            src={require("@assets/images/lanternsAnimation/S.png")}
          />
          <img
            style={{ height: windowHeight }}
            src={require("@assets/images/lanternsAnimation/S.png")}
          />
        </div>
        <div
          className="layer"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            animation: "fly 50s linear infinite",
          }}
        >
          <img
            style={{ height: windowHeight }}
            src={require("@assets/images/lanternsAnimation/XS.png")}
          />
          <img
            style={{ height: windowHeight }}
            src={require("@assets/images/lanternsAnimation/XS.png")}
          />
        </div>
      </div>
    </>
  );
};

export default ParallaxLanterns;
