import React, { useState, memo, Dispatch, useContext } from "react";
import moment from "moment";
import { useWindowSize } from "@hooks";
import {
  GlobalStateContext,
  GlobalDispatchContext,
  GlobalStateInterface,
  LANTERNS_DATA_ACTIONS,
} from "@context/GlobalContextProvider";
import LanternContainer from "@components/Lantern/Lantern.container";
import ShowLantern from "@components/ShowLantern";
import firebase from "gatsby-plugin-firebase";
const lanternsSample = require("@data/lanterns.sample.json"); // TODO: Remove when DB fills enough and no testing needed anymore (containes 500 entries)
// const lanternsSample = require("@data/threeLanterns.sample.json"); // TODO: Remove when DB fills enough and no testing needed anymore (contains 3 entries with dates 17-18-19 feb 2021)

export type LanternsType = {
  id: string;
  user: string;
  location: string;
  message: { fontType: string; message: string };
  sentDate: number;
  lanternStyle: number;
  read: number;
  sticker: string;
  active: boolean;
};

interface AllLanternsProps {
  lanternsData: [LanternsType];
  onOpenLantern: Dispatch<any>;
}

const AllLanterns = memo(
  ({ lanternsData, onOpenLantern }: AllLanternsProps) => {
    const getDates = lanternsData.map((lantern) =>
      moment(lantern.sentDate).format("X")
    );

    const latestDate = Math.max(...getDates);
    const earliestDate = Math.min(...getDates);

    return (
      <>
        {" "}
        {lanternsData
          .filter((lantern: LanternsType, index) => lantern.active === true)
          .map((lantern: LanternsType, index) => (
            <LanternContainer
              key={index}
              latestDate={latestDate}
              earliestDate={earliestDate}
              lantern={lantern}
              setOpenLantern={onOpenLantern}
            />
          ))}
      </>
    );
  }
);

function LanternsScreenContainer() {
  const state: GlobalStateInterface = useContext(GlobalStateContext);
  const dispatch: Dispatch<any> = useContext(GlobalDispatchContext);

  const [lanterns, setLanterns] = React.useState(state.lanternsData);
  const [openLantern, setOpenLantern] = useState(null);
  const [windowWidth, windowHeight] = useWindowSize();

  const useSampleData = false;

  const numberOfLanternsToFetch = windowWidth < 960 ? 30 : 60;

  React.useEffect(() => {
    const handleEscapeKeydown = (e) => {
      if (e.code === "Escape") {
        handleCloseLantern();
      }
    };
    window.addEventListener("keydown", handleEscapeKeydown);

    firebase
      .database()
      .ref("/lanterns")
      .limitToLast(numberOfLanternsToFetch)
      .once("value")
      .then((snapshot) => {
        const lanternsData = snapshot.val();

        const transformLanternsData = (data) =>
          Object.keys(data).map((id) => {
            return { id, ...data[id] };
          });

        const updatedLanternsData = useSampleData
          ? lanternsSample
          : transformLanternsData(lanternsData);

        if (updatedLanternsData.length === lanterns.length) return;

        setLanterns(updatedLanternsData);
        dispatch({
          type: LANTERNS_DATA_ACTIONS.UPDATE_DATA,
          payload: updatedLanternsData,
        });
      });

    return () => window.removeEventListener("keydown", handleEscapeKeydown);
  }, []);

  if (!lanterns) {
    return <div className="loader">Loading lanterns...</div>;
  }

  function handleCloseLantern() {
    setOpenLantern(false);
  }

  return (
    <div className="lanternContainer">
      <img className="moon" src={require("@assets/images/moon.png")} />
      <AllLanterns lanternsData={lanterns} onOpenLantern={setOpenLantern} />
      {openLantern && (
        <ShowLantern
          lantern={openLantern}
          textToCopy={window.location.href}
          clickOffLantern={handleCloseLantern}
        />
      )}
    </div>
  );
}

export default memo(LanternsScreenContainer);
